


















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class GroupNotFound extends Vue {
  jumpToHome() {
    this.$router.push({ name: 'home' })
  }
}
